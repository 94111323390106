import { Box, LinearProgress, Typography } from '@mui/material';
import { Suspense, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DigitalShelfContext } from '../../../contexts/DigitalShelf';
import useAnimationCanvas from '../../tools/useAnimationCanvas';
import { pointToBox } from '../../utils/converters';


export default function WallDrawer(props) {

	const {
		landmark,
		landmarksVisible,
		png,
		coords = [],
		wallLandmarks,
		canvasHeight,
		canvasWidth,
		originalWidth,
		originalHeight,
		colors,
		selectedStatus,
		selectedCategories,
		product,
		handleCanvasHover,
		handleCanvasLeave = (drw) => { },
		handleCanvasClick = (e, drw) => { },
		aislePromotions,
		aisleLandmarks,
		imgsDict,
		scannedBays,
		hoveredElements,
		handleCoordAlertInfo,
		getCoordAlertName,
	} = props;

	// Context
	const {
		selectedLandmark,
	} = useContext(DigitalShelfContext);

	const BADGE_RADIUS = 12;

	const { t } = useTranslation();
	const [landmarkLabels, setlandmarkLabels] = useState([]);
	const [localCanvasHeight, setLocalCanvasHeight] = useState(canvasHeight);
	const [localCanvasWidth, setLocalCanvasWidth] = useState(canvasWidth);
	const [localCanvasOriginalHeight, setLocalOriginalCanvasHeight] = useState(originalHeight);
	const [localCanvasOriginalWidth, setLocalCanvasOriginalWidth] = useState(originalWidth);
	const [scaleX, setScaleX] = useState(canvasWidth / originalWidth);
	const [scaleY, setScaleY] = useState(canvasHeight / originalHeight);
	const [allHoverAreas, setAllHoverAreas] = useState([])
	const [wallBugs, setWallBugs] = useState([])
	const speed = 0.4

	const [svgs, setSvgs] = useState(null);
	const [canvasRef, drawerReturn] = useAnimationCanvas();
	const [hoverCanvasRef] = useAnimationCanvas();
	const [mousePosition, setMousePosition] = useState({ x: -100, y: -100 });
	const [infoBoxData, setInfoBoxData] = useState(null)
	const [showInfoBox, setShowInfoBox] = useState(false)

	// Add new state for background image
	const [backgroundImage, setBackgroundImage] = useState(null);

	// Change zoom on wheel event
	const [currentZoom, setCurrentZoom] = useState(45);
	const minZoom = 37;
	const maxZoom = 190;

	useEffect(() => {
		const wallBugs = coords && coords.length ? [...coords, ...aislePromotions, ...aisleLandmarks] : [...aislePromotions, ...aisleLandmarks]
		const filteredWallBugs = wallBugs.filter(element => ((selectedStatus || {}).has(getCoordAlertName(landmark, element))));
		setWallBugs(filteredWallBugs);
	}, [wallLandmarks, selectedStatus])

	useEffect(() => {
		if (wallLandmarks && wallLandmarks.length && coords && coords.length) {
			let landmarksObj = {};
			wallLandmarks.forEach(l => { landmarksObj[l.landmark] = 0 });

			coords.forEach(coord => {
				// This is for the landmark's black box
				if (coord.landmark) {
					landmarksObj[coord.landmark]++;
				}
			});
			setlandmarkLabels(landmarksObj);
		}
	}, [JSON.stringify(wallLandmarks), JSON.stringify(coords)]);

	useEffect(() => {
		if (imgsDict) {
			setSvgs(imgsDict);
		}
	}, [imgsDict])

	useEffect(() => {
		setLocalOriginalCanvasHeight(originalHeight);
		setLocalCanvasOriginalWidth(originalWidth);
	}, [JSON.stringify(coords), canvasWidth, originalWidth, canvasHeight, originalHeight])

	useEffect(() => {
		setShowInfoBox(false)
	}, [selectedLandmark])


	const drawRectangleOnCanvas = ({
		ctx,
		p1, p2, p3, p4,
		scale_x, scale_y,
		colorFill = 'rgba(255, 164, 0, 0.4)',
		strokeStyle = "#212529",
		lineDash = null,
		lineWidth = 4,
		borderRadius = 0
	}
	) => {
		ctx.beginPath();
		ctx.fillStyle = colorFill;

		// Calculate scaled points
		const x1 = p1[0] * scale_x;
		const y1 = p1[1] * scale_y;
		const x2 = p2[0] * scale_x;
		const y2 = p2[1] * scale_y;
		const x3 = p3[0] * scale_x;
		const y3 = p3[1] * scale_y;
		const x4 = p4[0] * scale_x;
		const y4 = p4[1] * scale_y;

		// Start from top-left and draw clockwise
		ctx.moveTo(x1 + borderRadius, y1);

		// Top edge
		ctx.lineTo(x2 - borderRadius, y2);
		ctx.quadraticCurveTo(x2, y2, x2, y2 + borderRadius);

		// Right edge
		ctx.lineTo(x3, y3 - borderRadius);
		ctx.quadraticCurveTo(x3, y3, x3 - borderRadius, y3);

		// Bottom edge
		ctx.lineTo(x4 + borderRadius, y4);
		ctx.quadraticCurveTo(x4, y4, x4, y4 - borderRadius);

		// Left edge
		ctx.lineTo(x1, y1 + borderRadius);
		ctx.quadraticCurveTo(x1, y1, x1 + borderRadius, y1);

		ctx.closePath();

		ctx.fillStyle = colorFill;
		ctx.strokeStyle = strokeStyle;
		ctx.lineWidth = lineWidth;
		if (lineDash) ctx.setLineDash(lineDash);
		ctx.stroke();
		ctx.fill();
	}

	const drawGridRectangle = ({
		ctx,
		p1, p2, p3, p4,
		scale_x, scale_y,
		grids = [],
		lineWidth = 4,
		borderRadius = 0
	}) => {
		// Calculate scaled points
		const x1 = p1[0] * scale_x;
		const y1 = p1[1] * scale_y;
		const x2 = p2[0] * scale_x;
		const y2 = p2[1] * scale_y;
		const x3 = p3[0] * scale_x;
		const y3 = p3[1] * scale_y;
		const x4 = p4[0] * scale_x;
		const y4 = p4[1] * scale_y;

		// Calculate midpoints
		const midX = (x1 + x2) / 2;
		const midY = (y1 + y3) / 2;

		// Draw based on number of grids
		switch (grids.length) {
			case 1:
				// Single rectangle
				drawRectangleOnCanvas({
					ctx,
					p1: [p1[0], p1[1]],
					p2: [p2[0], p2[1]],
					p3: [p3[0], p3[1]],
					p4: [p4[0], p4[1]],
					scale_x,
					scale_y,
					colorFill: grids[0].colorFill,
					strokeStyle: grids[0].strokeStyle,
					lineWidth,
					borderRadius
				});
				break;

			case 2:
				// Two vertical rectangles
				drawRectangleOnCanvas({
					ctx,
					p1: [p1[0], p1[1]],
					p2: [midX / scale_x, p1[1]],
					p3: [midX / scale_x, p3[1]],
					p4: [p4[0], p4[1]],
					scale_x,
					scale_y,
					colorFill: grids[0].colorFill,
					strokeStyle: grids[0].strokeStyle,
					lineWidth,
					borderRadius
				});
				drawRectangleOnCanvas({
					ctx,
					p1: [midX / scale_x, p1[1]],
					p2: [p2[0], p2[1]],
					p3: [p3[0], p3[1]],
					p4: [midX / scale_x, p4[1]],
					scale_x,
					scale_y,
					colorFill: grids[1].colorFill,
					strokeStyle: grids[1].strokeStyle,
					lineWidth,
					borderRadius
				});
				break;

			case 3:
				// Two rectangles on top, one on bottom
				drawRectangleOnCanvas({
					ctx,
					p1: [p1[0], p1[1]],
					p2: [midX / scale_x, p1[1]],
					p3: [midX / scale_x, midY / scale_y],
					p4: [p1[0], midY / scale_y],
					scale_x,
					scale_y,
					colorFill: grids[0].colorFill,
					strokeStyle: grids[0].strokeStyle,
					lineWidth,
					borderRadius
				});
				drawRectangleOnCanvas({
					ctx,
					p1: [midX / scale_x, p1[1]],
					p2: [p2[0], p2[1]],
					p3: [p2[0], midY / scale_y],
					p4: [midX / scale_x, midY / scale_y],
					scale_x,
					scale_y,
					colorFill: grids[1].colorFill,
					strokeStyle: grids[1].strokeStyle,
					lineWidth,
					borderRadius
				});
				drawRectangleOnCanvas({
					ctx,
					p1: [p1[0], midY / scale_y],
					p2: [p2[0], midY / scale_y],
					p3: [p3[0], p3[1]],
					p4: [p4[0], p4[1]],
					scale_x,
					scale_y,
					colorFill: grids[2].colorFill,
					strokeStyle: grids[2].strokeStyle,
					lineWidth,
					borderRadius
				});
				break;

			case 4:
				// Four equal rectangles
				drawRectangleOnCanvas({
					ctx,
					p1: [p1[0], p1[1]],
					p2: [midX / scale_x, p1[1]],
					p3: [midX / scale_x, midY / scale_y],
					p4: [p1[0], midY / scale_y],
					scale_x,
					scale_y,
					colorFill: grids[0].colorFill,
					strokeStyle: grids[0].strokeStyle,
					lineWidth,
					borderRadius
				});
				drawRectangleOnCanvas({
					ctx,
					p1: [midX / scale_x, p1[1]],
					p2: [p2[0], p2[1]],
					p3: [p2[0], midY / scale_y],
					p4: [midX / scale_x, midY / scale_y],
					scale_x,
					scale_y,
					colorFill: grids[1].colorFill,
					strokeStyle: grids[1].strokeStyle,
					lineWidth,
					borderRadius
				});
				drawRectangleOnCanvas({
					ctx,
					p1: [p1[0], midY / scale_y],
					p2: [midX / scale_x, midY / scale_y],
					p3: [midX / scale_x, p3[1]],
					p4: [p4[0], p4[1]],
					scale_x,
					scale_y,
					colorFill: grids[2].colorFill,
					strokeStyle: grids[2].strokeStyle,
					lineWidth,
					borderRadius
				});
				drawRectangleOnCanvas({
					ctx,
					p1: [midX / scale_x, midY / scale_y],
					p2: [p2[0], midY / scale_y],
					p3: [p3[0], p3[1]],
					p4: [midX / scale_x, p4[1]],
					scale_x,
					scale_y,
					colorFill: grids[3].colorFill,
					strokeStyle: grids[3].strokeStyle,
					lineWidth,
					borderRadius
				});
				break;

			default:
				// If invalid number of grids, draw single rectangle with default style
				drawRectangleOnCanvas({
					ctx,
					p1: [p1[0], p1[1]],
					p2: [p2[0], p2[1]],
					p3: [p3[0], p3[1]],
					p4: [p4[0], p4[1]],
					scale_x,
					scale_y,
					colorFill: 'rgba(200, 200, 200, 0.4)', // default gray color
					strokeStyle: '#666666',
					lineWidth,
					borderRadius
				});
				console.warn(`Invalid number of grids provided: ${grids.length}. Expected 1-4 grids.`);
				break;
		}
	};

	// new function draw landmarks
	const drawLandmarksBays = (ctx, scale_x, scale_y, mouseX, mouseY) => {
		const hoverAreas = [];
		if (scannedBays && scannedBays.length) {
			const selectedBay = scannedBays.find(element => element.standard_name === selectedLandmark?.landmark)
			if (selectedBay) {
				let [p1, p2, p3, p4] = selectedBay.wall_bbox
				drawRectangleOnCanvas({ ctx, p1, p2, p3, p4, scale_x, scale_y, colorFill: "rgba(90, 70, 40, 0.3)", strokeStyle: "#1e8bea", lineDash: [6, 4], })
				// Coords
				const x1 = p1[0] * scale_x;
				const y1 = p1[1] * scale_y;
				const x2 = p2[0] * scale_x;

				// draw badge
				const badgeX = x2;
				const badgeY = y1 - BADGE_RADIUS - 5;

				ctx.beginPath();
				ctx.fillStyle = "#298ce3"; // badge color
				ctx.arc(badgeX, badgeY, BADGE_RADIUS, 0, 2 * Math.PI);
				ctx.fill();

				// Add dashed border
				ctx.setLineDash([5, 3]); // Pattern: 4px line, 2px gap
				ctx.lineWidth = 3;
				ctx.strokeStyle = "#1e8bea";
				ctx.stroke();
				ctx.setLineDash([]); // Reset to solid lines

				// text badge
				const detectedProducts = selectedBay?.bayInfo.total;
				ctx.fillStyle = "#fff";
				ctx.font = 'bold 11px Arial';
				ctx.textAlign = "center";
				ctx.textBaseline = "middle";
				ctx.fillText(detectedProducts, badgeX, badgeY);

				// saves badge coordinates for hover detection
				hoverAreas.push({
					x: badgeX - BADGE_RADIUS,
					y: badgeY - BADGE_RADIUS,
					width: BADGE_RADIUS * 2,
					height: BADGE_RADIUS * 2,
					xLandmark: x1,
					yLandmark: y1,
					info: {
						standardName: selectedBay.standard_name,
						productsDetected: detectedProducts,
					},
				});
				setAllHoverAreas(hoverAreas)
			}
		}
	}


	// Load image when png prop changes
	useEffect(() => {
		const image = new Image();
		image.src = png;
		image.onload = () => {
			setBackgroundImage(image);
		};
	}, [png]);

	// Modified drawFunc to include background image
	const drawFunc = () => {
		if (!canvasRef.current || !backgroundImage) return;

		const ctx = canvasRef.current.getContext('2d');
		ctx.clearRect(0, 0, localCanvasWidth, localCanvasHeight);

		// Draw background image
		ctx.drawImage(backgroundImage, 0, 0, localCanvasWidth, localCanvasHeight);

		if (landmarksVisible) {
			if (wallBugs && wallBugs.length) {
				wallBugs.forEach(element => {
					const coordAlertName = getCoordAlertName(landmark, element);
					const coordColors = handleCoordAlertInfo(element)
						.map(color => {
							return {
								colorFill: color.colorInfo.color, strokeStyle: color.colorInfo.color,
							}
						});

					if (selectedStatus && !(selectedStatus.has(coordAlertName))) return;
					if (selectedCategories && selectedCategories.has(element.name_category)) return;

					const isStockout = coordAlertName === 'stockout';
					drawerReturn(drawSqExpanded, [element, scaleX, scaleY, coordColors, isStockout])
				})
			}
			drawerReturn(drawLandmarksBays, [scaleX, scaleY])
		}
	}

	// Update drawFunc when background image loads or canvas properties change
	useEffect(() => {
		if (backgroundImage) {
			drawFunc();
		}
	}, [backgroundImage, localCanvasWidth, localCanvasHeight, landmarksVisible, wallBugs, currentZoom]);

	// New function to handle hover drawings
	const drawHovers = () => {
		if (!hoverCanvasRef.current) return;
		const ctx = hoverCanvasRef.current.getContext('2d');
		ctx.clearRect(0, 0, localCanvasWidth, localCanvasHeight);

		if (hoveredElements.length) {
			hoveredElements.forEach(element => {
				const coordColor = getCoordAlertName(landmark, element);
				let colorFill = colors[coordColor].focusedColor;
				const strokeStyle = colors[coordColor].focusedColor;
				let max_tl_x;
				let max_tl_y;
				let max_br_x;
				let max_br_y;
				let borderRadius = 0;
				if (element.hasOwnProperty('pixel_z')) {
					const radius = 40;
					borderRadius = 2;
					({ x1: max_tl_x, y1: max_tl_y, x2: max_br_x, y2: max_br_y } = pointToBox({ pixel_x: element.pixel_x, pixel_z: element.pixel_z, radius }));
				} else {
					max_tl_x = element.facing_top_left_x;
					max_tl_y = element.facing_top_left_y;
					max_br_x = element.facing_bottom_right_x;
					max_br_y = element.facing_bottom_right_y;
					colorFill = colorFill + '50';
				}
				drawRectangleOnCanvas({
					ctx,
					p1: [max_tl_x, max_tl_y],
					p2: [max_br_x, max_tl_y],
					p3: [max_br_x, max_br_y],
					p4: [max_tl_x, max_br_y],
					scale_x: scaleX,
					scale_y: scaleY,
					colorFill: colorFill,
					strokeStyle: strokeStyle,
					lineWidth: 1,
					borderRadius,
				})
			});
		}
	}

	// Update hover effect handling
	useEffect(() => {
		// draw the highlighted elements
		drawHovers();
		// redraw the canvas to withdraw the square in the middle of the bbox if the element is clicked
		drawFunc();

	}, [hoveredElements]);

	const drawSqExpanded = (ctx, element, scale_x, scale_y, coordColors, isStockout) => {
		let max_tl_x;
		let max_tl_y;
		let max_br_x;
		let max_br_y;
		let borderRadius = 0;
		const radius = 20;
		if (element.hasOwnProperty('pixel_z')) {
			borderRadius = 2;
			({ x1: max_tl_x, y1: max_tl_y, x2: max_br_x, y2: max_br_y } = pointToBox({ pixel_x: element.pixel_x, pixel_z: element.pixel_z, radius }));
		} if (!isStockout) {
			max_tl_x = element['facing_top_left_x'];
			max_tl_y = element['facing_top_left_y'];
			max_br_x = element['facing_bottom_right_x'];
			max_br_y = element['facing_bottom_right_y'];

			const center_x = (max_tl_x + max_br_x) / 2;
			const center_y = (max_tl_y + max_br_y) / 2;
			({ x1: max_tl_x, y1: max_tl_y, x2: max_br_x, y2: max_br_y } = pointToBox({ pixel_x: center_x, pixel_z: center_y, radius }));
		}
		else {
			max_tl_x = element['facing_top_left_x'];
			max_tl_y = element['facing_top_left_y'];
			max_br_x = element['facing_bottom_right_x'];
			max_br_y = element['facing_bottom_right_y'];
			coordColors.forEach(color => {
				color.colorFill += '10';
			})
		}


		// Ignore draw a square in the middle of the bbox if the element is clicked
		if (!element.isClicked) {
			drawGridRectangle({
				ctx,
				p1: [max_tl_x, max_tl_y],
				p2: [max_br_x, max_tl_y],
				p3: [max_br_x, max_br_y],
				p4: [max_tl_x, max_br_y],
				scale_x, scale_y,
				lineWidth: 1,
				borderRadius,
				grids: coordColors,
			})
		}
	}

	const handleClickEvent = (event) => {
		const canvas = canvasRef.current;
		const rect = canvas.getBoundingClientRect();
		const mouseX = event.clientX - rect.left;
		const mouseY = event.clientY - rect.top;

		// check click
		const clickedBadge = allHoverAreas.find(area =>
			mouseX >= area.x &&
			mouseX <= area.x + area.width &&
			mouseY >= area.y &&
			mouseY <= area.y + area.height
		);

		if (clickedBadge) {
			setMousePosition({ x: mouseX, y: mouseY });
			setInfoBoxData({ ...clickedBadge.info, x: clickedBadge.xLandmark, y: clickedBadge.yLandmark });
			setShowInfoBox((prev) => !prev);
		}
	}


	// redraw the canvas with the current mouse position
	useEffect(() => {
		const canvas = canvasRef.current;
		if (!canvas) return;

		const ctx = canvas.getContext('2d');
		ctx.clearRect(0, 0, canvas.width, canvas.height); // clear the canvas before redrawing
		drawLandmarksBays(ctx, scaleX, scaleY, mousePosition.x, mousePosition.y);

	}, [mousePosition, scaleX, scaleY, scannedBays]);


	const scaleMousePosition = (canvas, evt) => {
		const rect = canvas.getBoundingClientRect(); // Abs. size of element
		const scaleX = canvas.width / rect.width;    // Relationship bitmap vs. element for x
		const scaleY = canvas.height / rect.height;  // Relationship bitmap vs. element for y

		return {
			x: (evt.clientX - rect.left) * scaleX,   // Scale mouse coordinates after they have
			y: (evt.clientY - rect.top) * scaleY     // been adjusted to be relative to element
		}
	}


	const modifyZoomOnWheel = (event) => {
		event.preventDefault();
		const canvas = canvasRef.current;
		const bounding = canvas.getBoundingClientRect();

		let newZoom = currentZoom * (event.deltaY * (-0.001) + 1)
		newZoom = Math.max(minZoom, Math.min(maxZoom, newZoom));

		const ratio = newZoom / currentZoom;
		setCurrentZoom(newZoom);
		const container = document.getElementById("container");
		canvas.resized_width = bounding.width;

		canvas.resized_height = bounding.height;

		//this maintains the cursor on the same pixel where it was before zooming
		container.scrollLeft = container.scrollLeft + event.offsetX * (-1 + ratio)
		container.scrollTop = container.scrollTop + event.offsetY * (-1 + ratio)
	}

	useEffect(() => {
		const canvas = canvasRef.current;
		if (!canvas) return;
		canvas.addEventListener('click', handleClickEvent);
		canvas.addEventListener('wheel', modifyZoomOnWheel);

		return () => {
			canvas.removeEventListener('click', handleClickEvent);
			canvas.removeEventListener('wheel', modifyZoomOnWheel);
		}
	}, [canvasRef, JSON.stringify(allHoverAreas), currentZoom]);

	useEffect(() => {
		if (canvasRef?.current) {
			drawFunc();
			canvasRef.current.onmousemove = (event) => {
				const canvas = document.getElementById('canvas');
				const { x, y } = scaleMousePosition(canvas, event);
				handleCanvasHover({ clientX: x, clientY: y }, drawerReturn);
			}

			canvasRef.current.onclick = (event) => {
				const canvas = document.getElementById('canvas');
				const { x, y } = scaleMousePosition(canvas, event);
				handleCanvasClick({ clientX: x, clientY: y, canvasWidth, canvasHeight }, drawerReturn);
			}

			canvasRef.current.onmouseleave = () => {
				handleCanvasLeave(drawerReturn);
			}
		}
	}, [wallBugs, currentZoom]);

	return (
		<Box
			id="container"
			sx={{
				position: 'relative',
				width: '100%',
				overflow: 'scroll',
				height: '51vh',
			}}>
			<Suspense fallback={<LinearProgress sx={{ width: '98%', mx: 'auto' }} color="secondary" />}>
				<canvas
					id="canvas"
					ref={canvasRef}
					tabIndex="1"
					height={localCanvasHeight}
					width={localCanvasWidth}
					style={{
						width: "auto",
						height: `${currentZoom}vh`,
						zIndex: 2,
						touchAction: 'none',
					}}
				/>
				<canvas
					id="hoverCanvas"
					ref={hoverCanvasRef}
					height={localCanvasHeight}
					width={localCanvasWidth}

					style={{ width: "auto", height: `${currentZoom}vh`, pointerEvents: 'none', zIndex: 1, position: 'absolute', top: 0, left: 0, }}
				/>
			</Suspense>
			{showInfoBox && (
				<Box sx={{
					position: 'absolute',
					minWidth: '5em',
					maxHeight: '5em',
					height: 'min-content',
					color: 'white',
					border: '3px solid #1e8bea',
					top: `calc(${infoBoxData.y}px - 1.7em)`,
					left: `calc(${infoBoxData?.x}px - 0.19em)`,
					background: 'rgba(90, 70, 40, 0.5)',
					paddingX: 1,
					borderStyle: 'dashed'
				}}>
					<Typography variant='subtitle2'>
						{infoBoxData?.standardName}
					</Typography>
				</Box>
			)}
		</Box>
	);
}

