export const COLORS = {
  stock: {
    color: '#349E15',
    focusedColor: '#ABF096',
    color2: '#143f07',
    text: '#fff',
  },
  stockout: {
    color: '#D80A3B',
    focusedColor: '#EA99AC',
    color2: '#60051b',
    text: '#fff',
  },
  spread_to_fill: {
    color: '#DB9105',
    focusedColor: '#FFD8AD',
    color2: '#7a6235',
    text: '#000',
  },
  price_difference: {
    color: '#FFD740',
    focusedColor: '#FFEDAB',
    color2: '#b79d00',
    text: '#000',
  },
  hua: {
    color: '#4065F6',
    focusedColor: '#91C8E4',
    color2: '#2E4890',
    text: '#fff',
  },
  promotion: {
    color: '#F6F4EB',
    focusedColor: '#fff',
    color2: '#ffdef0',
    text: '#000',
  },
  misplaced_product: {
    color: '#40F8FF',
    focusedColor: '#97FFF6',
    color2: '#DCFFFD',
    text: '#000',
  },
  extra_label: {
    color: '#FD8732',
    focusedColor: '#FDAF6F',
    color2: '#ffdef0',
    text: '#000',
  },
};

export function colorBasedOnTheme(theme, object) {
  let isDarkTheme = theme.palette.type === 'dark' ? true : false;
  let color = '';

  switch (object) {
    // Color
    case 'color':
      color = isDarkTheme ? 'primary.contrastText' : 'common.dark';
      break;
    case 'color-darkTheme':
      color = 'primary.contrastText';
      break;
    // Background
    case 'bgcolor-darkTheme':
      color = isDarkTheme ? 'paper.main' : 'primary.main';
      break;
    // case 'bgcolor-white':
    //   color = isDarkTheme ? 'paper.main' : 'common.white';
    //   break;
    // Effects
    case 'bgcolor-hover':
      color = isDarkTheme ? 'rgba(98, 98, 130, 0.1)' : 'rgba(0, 0, 0, 0.04)';
      break;
    case 'bgcolor-hover-darkTheme':
      color = 'rgba(98, 98, 130, 0.1)';
      break;
    default:
      break;
  }

  return color;
}

export function colorBasedOnAlert(alert) {
  let color = '';

  switch (alert) {
    case 'in_stock':
      color = 'rgba(0, 179, 24, 1)'
      break
    case 'price_difference':
      color = 'rgba(255, 215, 64, 1)'
      break;
    case 'spread_to_fill':
      color = 'rgba(255 ,164 ,0, 1)'
      break;
    case 'stockout':
    case 'zero_stock':
    case 'shelf_out':
      color = 'rgba(255, 0, 66, 1)'
      break;
    case 'inactive_promotion':
      color = 'rgba(136, 70, 207, 1)'
      break;
    default:
      color = 'rgba(255, 0, 204, 1)'
      break;
  }
  return color
}

export function highlightedProductColor() {
  return 'cyan'
}

export function colorBasedOnTaskCompletion(completed) {
  let color = '';

  if (completed) {
    color = 'success.light';
  } else {
    color = 'error.light'
  }
  return color
}

export function mapBackgroundColor() {
  return 'rgba(196, 196, 217, 255)'
}

export function checkColor(product) {
  let color = COLORS['stock'].color;
  if (product.stockout) color = COLORS['stockout'].color;
  if (product.spread_to_fill) color = COLORS['spread_to_fill'].color;
  else if (product.price_difference) color = COLORS['price_difference'].color;
  else if (product.extra_label) color = COLORS['extra_label'].color;
  else if (product.misplaced_product) color = COLORS['misplaced_product'].color;
  else if (!product.landmark_type && product.hasOwnProperty('pixel_z')) color = COLORS['promotion'].color;
  else if (product.landmark_type) color = COLORS['hua'].color;

  return color;
};


export const checkIconsName = product => {
  const iconNames = [];
  if (product.hasOwnProperty('stockout')) {
    if (product.stockout) iconNames.push('stock-out');
    else if (product.spread_to_fill) iconNames.push('stock-out');
    if (product.price_difference) iconNames.push('price-difference');
    if (product.extra_label) iconNames.push('price-difference');
    if (product.misplaced_product) iconNames.push('misplaced-product');
    if (product.landmark_type) iconNames.push('location');
    if (iconNames.length === 0) iconNames.push('stock');
  } else {
    iconNames.push('promotion');
  }
  return iconNames;
};

// TODO: preguntar si quieres que los spread-to-fill salgan con los in-stock
export const handleCoordAlertInfo = product => {
  const alertColors = [];

  if (product.spread_to_fill) {
    alertColors.push({ colorInfo: COLORS['spread_to_fill'], iconName: 'stock-out', title: 'spread_to_fill' });
  } else if (product.stockout) {
    alertColors.push({ colorInfo: COLORS['stockout'], iconName: 'stock-out', title: 'stockout' });
  }
  if (product.price_difference) alertColors.push({ colorInfo: COLORS['price_difference'], iconName: 'price-difference', title: 'price_difference' });
  if (product.extra_label) alertColors.push({ colorInfo: COLORS['extra_label'], iconName: 'price-difference', title: 'extra_label' });
  if (product.misplaced_product) alertColors.push({ colorInfo: COLORS['misplaced_product'], iconName: 'misplaced-product', title: 'misplaced_product' });
  if (!product.landmark_type && product.hasOwnProperty('pixel_z')) alertColors.push({ colorInfo: COLORS['promotion'], iconName: 'promotion', title: 'promotion' });
  if (product.landmark_type) alertColors.push({ colorInfo: COLORS['hua'], iconName: 'location', title: 'location' });

  if (alertColors.length === 0) alertColors.push({ colorInfo: COLORS['stock'], iconName: 'stock', title: 'stock' });

  return alertColors;
}
