import React, { useContext } from 'react';
import { format, pointToBox } from '../../utils/converters';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { handleCoordAlertInfo } from '../../utils/colors';
// Icons

import { isEmptyOrUndefined } from "@zippeditoolsjs/blocks";

// My Components
import { getChainMeaning } from 'core/utils/parsers';
import { DigitalShelfContext } from '../../../contexts/DigitalShelf';
import Modal from '../../tools/Modal';
import WallDrawer from './WallDrawer';
import WallMetadata from './WallMetadata';

// constants
const GRID_CELL_SIZE = 25; // Size of each grid cell in pixels

const getCoordAlertName = (landmark, element) => {
  let coordColor = 'stock';
  if (element.stockout) coordColor = 'stockout';
  if (element.spread_to_fill) coordColor = 'spread_to_fill';
  else if (element.price_difference) coordColor = 'price_difference';
  else if (element.extra_label) coordColor = 'extra_label';
  else if (element.misplaced_product) coordColor = 'misplaced_product'
  else if (!element.landmark_type && element.hasOwnProperty('pixel_z')) coordColor = 'promotion';
  else if (element.landmark_type) coordColor = 'hua';

  // color selected landmark only (nice to have)
  if (landmark && landmark.landmark && element.landmark
    && element.landmark !== landmark.landmark
    && !element.landmark_type
  ) coordColor = 'promotion';

  return coordColor;
}

// Add this new function to create the spatial hash
const createSpatialHash = (coordsData, canvasWidth, canvasHeight, scale_x, scale_y) => {
  const grid = {};

  const getGridCells = (x1, y1, x2, y2) => {
    const startCol = Math.floor(x1 / GRID_CELL_SIZE);
    const endCol = Math.floor(x2 / GRID_CELL_SIZE);
    const startRow = Math.floor(y1 / GRID_CELL_SIZE);
    const endRow = Math.floor(y2 / GRID_CELL_SIZE);

    const cells = [];
    for (let col = startCol; col <= endCol; col++) {
      for (let row = startRow; row <= endRow; row++) {
        cells.push(`${col},${row}`);
      }
    }
    return cells;
  };

  coordsData.forEach((coord, idx) => {
    let x1, y1, x2, y2;

    if (coord.hasOwnProperty('pixel_z')) {
      // For landmark icons
      ({ x1, y1, x2, y2 } = pointToBox({ pixel_x: coord.pixel_x, pixel_z: coord.pixel_z, radius: 32, scale_x, scale_y }));
    } else {
      // For product facings
      x1 = coord.facing_top_left_x * scale_x;
      y1 = coord.facing_top_left_y * scale_y;
      x2 = coord.facing_bottom_right_x * scale_x;
      y2 = coord.facing_bottom_right_y * scale_y;
    }

    const cells = getGridCells(x1, y1, x2, y2);
    cells.forEach(cell => {
      if (!grid[cell]) {
        grid[cell] = [];
      }
      grid[cell].push({
        idx,
        coord,
        bounds: { x1, y1, x2, y2 }
      });
    });
  });

  return grid;
};

export default function Wall(props) {
  const { t, i18n } = useTranslation();
  const {
    selectedClient,
    wallImageData,
    landmarkInfo,
    isLoadingCoordsInfo,
    isLoadingWallImage,
    wallLandmarks,
    getDigitalShelfExcel,
    selectedProductState,
    clearCoordsState,
    clearWallImageData,
    aislePromotions,
    aisleLandmarks,
    misplacedSignedImages: incomingMisplacedSignedImages,
    getMisplacedProductSignedImages,
    getCoordsForExcel,
    loadingCoordsForExcel,
    coordsForExcel,
    imgsDict = null,
    getNumberFormat,
    currencySymbol,
    isLoadingMaps,
    storeMaps,
    scannedBaysData,
    clientFormats,
  } = props;

  const wallImage = new Image();

  const colors = {
    stock: {
      color: '#349E15',
      focusedColor: '#ABF096',
      color2: '#143f07',
      text: '#fff',
    },
    stockout: {
      color: '#D80A3B',
      focusedColor: '#EA99AC',
      color2: '#60051b',
      text: '#fff',
    },
    spread_to_fill: {
      color: '#DB9105',
      focusedColor: '#FFD8AD',
      color2: '#7a6235',
      text: '#000',
    },
    price_difference: {
      color: '#FFD740',
      focusedColor: '#FFEDAB',
      color2: '#b79d00',
      text: '#000',
    },
    hua: {
      color: '#4065F6',
      focusedColor: '#91C8E4',
      color2: '#2E4890',
      text: '#fff',
    },
    promotion: {
      color: '#F6F4EB',
      focusedColor: '#fff',
      color2: '#ffdef0',
      text: '#000',
    },
    misplaced_product: {
      color: '#40F8FF',
      focusedColor: '#97FFF6',
      color2: '#DCFFFD',
      text: '#000',
    },
    extra_label: {
      color: '#FD8732',
      focusedColor: '#FDAF6F',
      color2: '#ffdef0',
      text: '#000',
    },
  };

  const [canvasHeight, setCanvasHeight] = useState(500);
  const [canvasWidth, setCanvasWidth] = useState(1000);
  const [originalWidth, setOriginalWidth] = useState(null);
  const [originalHeight, setOriginalHeight] = useState(null);
  const [zoom, setZoom] = useState(true);
  const [excelColumns, setExcelColumns] = useState(new Set());
  const [landmarksVisible, setLandmarksVisible] = useState(true);
  const [alertPills, setAlertPills] = useState({
    stock: 0,
    stockout: 0,
    spread_to_fill: 0,
    price_difference: 0,
    promotion: 0,
    hua: 0,
    misplaced_product: 0,
    extra_label: 0,
  });
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(
    new Set([
      'stockout',
    ]),
  );
  const [selectedCategories, setSelectedCategories] = useState(new Set());
  const isHoverOn = useRef(null);
  const hoveredElements = useRef([]);
  const selectedProductIndex = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [parsedLandmarks, setParsedLandmarks] = useState([]);


  // Used for not showing the tooltip when the user is hovering on it, but the productType is not selected
  const [selectedProductType, setSelectedProductType] = useState(null);
  const [isWallExtend, setIsWallExtend] = useState(true);
  const wallBoxRef = useRef(null);
  const wallViewRef = useRef(null);
  const wallScroll = useRef(null);

  const [baysDataWithCounts, setBaysDataWithCounts] = useState([])

  // Context
  const {
    dateState,
    selectedStore,
    selectedProduct,
    setWallSignedUrl,
    setSelectedProduct,
    setMisplacedSignedImages,
    setZoomedWallImageStyle,
    setMissingLabelInfo,
    selectedAisle,
    setProductPosPercent,
    selectedLandmark,
    productSearched,
  } = useContext(DigitalShelfContext);

  const countLabelTotalAndAlerts = (value) => {
    let info = {
      alertCount: 0,
      total: 0
    }
    if (landmarkInfo && landmarkInfo.coords && Object.keys(landmarkInfo.coords)) {
      landmarkInfo.coords.forEach(label => {
        if (label.landmark === value) {
          if (label.stockout) {
            info.alertCount++;
          }
          info.total++;
        }
      })
    }
    return info;
  }

  useEffect(() => {
    let auxList = []
    scannedBaysData.forEach(bay => {
      let bayInfo = countLabelTotalAndAlerts(bay.standard_name);
      auxList.push({ ...bay, bayInfo });
    });
    setBaysDataWithCounts(auxList)
  }, [scannedBaysData, landmarkInfo])

  useEffect(() => {
    setIsWallExtend(true);
  }, [productSearched]);

  useEffect(() => {
    if (wallLandmarks && wallLandmarks.length) {
      const parsedLandmarks = {}
      wallLandmarks.forEach((landmark, idx) => {
        if (landmark.hasOwnProperty('pixel_x')) {
          const x = (landmark.pixel_x > 0 ? landmark.pixel_x : 4);
          let nextX = 0;
          if (idx < wallLandmarks.length - 1) {
            nextX = wallLandmarks[idx + 1].pixel_x;
          } else {
            nextX = null;
          }
          const endX = x < nextX ? nextX : landmark.next_landmark_position ? landmark.next_landmark_position : 0;
          parsedLandmarks[landmark.landmark] = {
            ...landmark,
            px_init: x,
            px_end: endX,
            landmarkPercentageInit: x / originalWidth,
            landmarkPercentageEnd: endX / originalWidth,
            originalWallWidth: originalWidth,
          };
        }
      });
      setParsedLandmarks(parsedLandmarks);
    };
  }, [wallLandmarks]);

  useEffect(() => {
    if (incomingMisplacedSignedImages) {
      setMisplacedSignedImages(incomingMisplacedSignedImages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingMisplacedSignedImages]);

  useEffect(() => {
    if (selectedProduct && selectedProduct?.misplaced_product) {
      getMisplacedProductSignedImages(selectedStore.store_code, selectedProduct.blob)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct])

  // recover missing label data
  useEffect(() => {
    setMissingLabelInfo(landmarkInfo?.missing_label_data);
  }, [landmarkInfo]);


  // load img data
  useEffect(() => {
    if (!isLoadingWallImage && Object.keys(wallImageData).length > 0) {
      wallImage.src = wallImageData.signed_url;
      wallImage.onload = () => {
        setWallSignedUrl(wallImageData.signed_url);
        let canvas_element = document.getElementById('wallScroll');
        if (canvas_element) {
          let canvas_height = canvas_element.offsetHeight;
          let newImgWidth =
            canvas_height * (wallImage.width / wallImage.height);

          setOriginalWidth(wallImage.naturalWidth);
          setOriginalHeight(wallImage.naturalHeight);

          setCanvasWidth(newImgWidth);
          setCanvasHeight(canvas_height);
        }
      };
    }
  }, [wallImageData]);

  // alert pills
  useEffect(() => {
    if (
      landmarkInfo &&
      Object.keys(landmarkInfo) &&
      landmarkInfo.coords &&
      landmarkInfo.coords.length
    ) {
      let excelCols = new Set();
      let pills = {
        stock: 0,
        zero_stock: 0,
        stockout: 0,
        spread_to_fill: 0,
        price_difference: 0,
        hua: 0,
        promotion: 0,
        misplaced_product: 0,
        extra_label: 0,
      };
      landmarkInfo.coords.forEach(coord => {
        if (coord.misplaced_product) {
          pills.misplaced_product++;
          excelCols.add('misplaced_product');
        }
        if (coord.stockout) {
          pills.stockout++;
          excelCols.add('stockout');
        }
        if (coord.spread_to_fill) {
          pills.spread_to_fill++;
          excelCols.add('spread_to_fill');
        }
        if (coord.price_difference) {
          pills.price_difference++;
          excelCols.add('price_difference');
        }

        if (coord.extra_label) {
          pills.extra_label++;
          excelCols.add('extra_label');
        } else pills.stock++;
      });
      wallLandmarks.forEach(l => {
        pills.hua++;
      });
      aislePromotions.forEach(l => {
        pills.promotion++;
      });
      setAlertPills(pills);
      setExcelColumns(excelCols);
    }
  }, [landmarkInfo]);

  // category filters
  useEffect(() => {
    if (
      landmarkInfo &&
      Object.keys(landmarkInfo) &&
      landmarkInfo.coords &&
      landmarkInfo.coords.length
    ) {
      let pills = [];
      landmarkInfo.coords.forEach(coord => {
        if (coord.name_category && !pills.includes(coord.name_category)) {
          pills.push(coord.name_category);
        }
      });
      pills.sort((a, b) => {
        if (a > b) return 1;
        return -1;
      });
      setCategoryFilters(pills);
    }
  }, [landmarkInfo]);

  // scroll to landmark
  useEffect(() => {
    if (
      canvasWidth &&
      originalWidth &&
      selectedLandmark &&
      selectedLandmark.pixel_x
    ) {
      let w = document.getElementById('wallScroll');
      let scale_x = canvasWidth / originalWidth;
      w.scrollTo(selectedLandmark.pixel_x * scale_x - 30, 0);
    }
  }, [selectedLandmark, canvasWidth, originalWidth]);

  // scroll to product
  useEffect(() => {
    if (
      canvasWidth &&
      originalWidth &&
      selectedProductState &&
      Object.keys(selectedProductState).length > 0 &&
      landmarkInfo?.coords?.length > 0
    ) {
      for (let index = 0; index < landmarkInfo.coords.length; index++) {
        const coord = landmarkInfo.coords[index];
        if (coord?.item === selectedProductState?.item) {
          const w = document.getElementById('wallScroll');
          const scale_x = canvasWidth / originalWidth;
          const posX = coord?.pixel_z ? coord.pixel_x : coord.facing_bottom_right_x;
          const scrollValue = posX * scale_x;
          w.scrollTo(scrollValue - 30, 0);
          handleProductPosPercent(posX, originalWidth);
          break;
        }
      }
    }
  }, [selectedProductState, landmarkInfo, canvasWidth, originalWidth]);

  useEffect(() => {
    clearCoordsState();
    clearWallImageData();
  }, [selectedStore]);

  // Get the type of alert of the selected product
  useEffect(() => {
    if (selectedProduct && Object.keys(selectedProduct).length > 0) {
      let productType = 'stock';
      if (selectedProduct.stockout) productType = 'stockout';
      else if (selectedProduct.spread_to_fill) productType = 'spread_to_fill';
      else if (selectedProduct.price_difference)
        productType = 'price_difference';
      else if (selectedProduct.extra_label) productType = 'extra_label';
      else if (selectedProduct.misplaced_product)
        productType = 'misplaced_product';
      else if (
        !selectedProduct.landmark_type &&
        selectedProduct.hasOwnProperty('pixel_z')
      )
        productType = 'promotion';
      else if (selectedProduct.landmark_type) productType = 'hua';
      setSelectedProductType(productType);
    }
  }, [selectedProduct]);

  // general pill selection
  const handleSelectionChanged = (value, selectedValues, setSelected) => {
    const newSet = new Set(selectedValues);
    if (newSet.has(value)) newSet.delete(value);
    else newSet.add(value);
    setSelected(newSet);
  };

  // Get the formatting (currency, numbers, data) based on the client_id
  useEffect(() => {
    if (!isEmptyOrUndefined(selectedClient, 'object')) {
      getNumberFormat(selectedClient?.client_id)
    }
  }, [selectedClient])

  // img product bugs
  const handleShowInfo = () => {
    setZoom(!zoom);
    setLandmarksVisible(!landmarksVisible);
  };

  // img zoom
  const handleZoom = () => {
    setLandmarksVisible(!landmarksVisible);

    setZoom(!zoom);
  };

  const zoomImageSquare = ({
    originalWidth,
    originalHeight,
    mouseX,
    mouseY,
  }) => {
    const viewerCanvasHeight =
      document.getElementById('wallScroll').offsetHeight; // s.windowHeight //insideWrapper.offsetHeight; // s.windowHeight - 413;
    const canvasWidth = document.getElementById('canvas').offsetWidth;
    const CENTER_Y = 10;

    const top = (-(mouseY - CENTER_Y) * originalHeight) / viewerCanvasHeight;
    const left = -((mouseX * originalWidth) / canvasWidth) + 314 / 2; // 314 is the width of the Box wich contains the zoomed image
    const newStyle = {
      zIndex: 1,
      width: `${originalWidth}px`,
      height: `${originalHeight}px`,
      display: 'block',
      position: 'relative',
      top: `${top}px`,
      left: `${left}px`,
    };
    setZoomedWallImageStyle(newStyle);
  };

  const spatialHashRef = useRef([]);

  // Add effect to reset spatial hash when data changes
  useEffect(() => {
    spatialHashRef.current = [];
    if (landmarkInfo?.coords?.length) {
      const coordsData = [
        ...(landmarkInfo.coords ?? []),
        ...(aislePromotions ?? []),
        ...(aisleLandmarks ?? []),
      ];

      const scale_x = canvasWidth / originalWidth;
      const scale_y = canvasHeight / originalHeight;
      spatialHashRef.current = createSpatialHash(coordsData, canvasWidth, canvasHeight, scale_x, scale_y);
    }
  }, [landmarkInfo, aislePromotions, aisleLandmarks, canvasWidth, canvasHeight]);

  const getMousePos = ({ clientX: x, clientY: y, isClicked = false }) => {
    let notFound = true;

    const coordsData = [
      ...(landmarkInfo.coords ?? []),
      ...(aislePromotions ?? []),
      ...(aisleLandmarks ?? []),
    ];

    if (coordsData && coordsData.length) {
      // Get relevant grid cell for mouse position
      const col = Math.floor(x / GRID_CELL_SIZE);
      const row = Math.floor(y / GRID_CELL_SIZE);
      const cell = `${col},${row}`;

      // Check only items in current cell
      const itemsToCheck = spatialHashRef.current[cell] || [];

      const filteredItemsToCheck = itemsToCheck.filter(element => ((selectedStatus || {}).has(getCoordAlertName(selectedLandmark, element.coord))));
      let beforeClicked = null;

      for (const { idx, coord, bounds } of filteredItemsToCheck) {
        const mouseIn = x >= bounds.x1 && x <= bounds.x2 &&
          y >= bounds.y1 && y <= bounds.y2;

        coord['isHoverOn'] = mouseIn;
        if (coord['isClicked']) {
          beforeClicked = coord;
          coord['isClicked'] = false;
        }

        if (mouseIn && (!isHoverOn.current || idx !== selectedProductIndex.current)) {
          notFound = false;
          isHoverOn.current = true;
          hoveredElements.current = [coord];
          if (isClicked) {
            coord['isClicked'] = true;
            selectedProductIndex.current = idx;
            setSelectedProduct(coord);
          }
          break;
        } else if (mouseIn && isHoverOn.current && !isClicked) {
          notFound = false;
          break;
        } else if (mouseIn && isHoverOn.current && beforeClicked) {
          notFound = false;
          break;
        }

      }
    }

    if (notFound) {
      isHoverOn.current = false;
      hoveredElements.current = [];
    }
  };

  const handleCanvasHover = event => {
    getMousePos({ clientX: event.clientX, clientY: event.clientY });
  };

  const handleCanvasLeave = event => {
    isHoverOn.current = false;
  }

  const handleProductPosPercent = (posX, canvasWidth) => {
    const posPercent = posX / canvasWidth;
    setProductPosPercent(posPercent);
    if (isWallExtend && isHoverOn.current) setIsWallExtend(false);
  }

  const handleCanvasClick = (event, drw) => {
    const { posx: posX } = selectedProduct;
    const { canvasWidth } = event;
    handleProductPosPercent(posX, canvasWidth);
    getMousePos({ clientX: event.clientX, clientY: event.clientY, isClicked: true });
  };

  const handleExcelDownload = all_data => {
    if (all_data) {
      getCoordsForExcel(
        selectedStore.store_id,
        selectedClient.supplier_id,
        selectedAisle['session'],
        format(dateState, null, 'yyyy-MM-dd'),
      );
    } else {
      const columns = [
        'item',
        'ean',
        'price',
        'aisle',
        'landmark',
        'texts',
        'name_category',
        ...excelColumns,
      ];
      const columnsTranslated = [t('cws_app.Walls.date', 'Date'), ...columns].map(
        col => getChainMeaning(t, selectedStore?.chain_name, col),
      );
      const formattedDate = format(dateState, i18n.language, clientFormats.date_format);
      const data = landmarkInfo?.coords?.map(position => {
        return [
          formattedDate,
          ...columns.map(col => position[col]),
        ];
      });

      const filename = `Shelf ${selectedStore?.store_code}-(${selectedAisle?.code})-${formattedDate.replaceAll('/', '-')}`;
      getDigitalShelfExcel({
        excelData: [columnsTranslated, ...data],
        excelFileName: filename,
      });
    }
    setModalOpen(false);
  };

  useEffect(() => {
    if (coordsForExcel) {
      const columns = [
        'item',
        'ean',
        'price',
        'aisle',
        'landmark',
        'texts',
        'name_category',
        ...excelColumns,
      ];
      let columnsTranslated = [t('cws_app.Walls.date', 'Date'), ...columns].map(
        col => getChainMeaning(t, selectedStore?.chain_name, col),
      );
      const formattedDate = format(dateState, i18n.language, clientFormats.date_format);
      const data = coordsForExcel?.coords?.map(position => {
        return [
          formattedDate,
          ...columns.map(col => position[col]),
        ];
      });

      const filename = `Shelf ${selectedStore?.store_code}-${formattedDate.replaceAll('/', '-')}`;
      getDigitalShelfExcel({
        excelData: [columnsTranslated, ...data],
        excelFileName: filename,
      });
    }
  }, [coordsForExcel]);

  const checkLandmarksAvailable = () => {
    return landmarkInfo.coords && landmarkInfo.coords.length > 0;
  };

  // on window resize
  const handleResize = (isWallExtend) => {
    if (wallBoxRef.current) {
      const SIDE_METADATA_SIZE = 60;
      const fullWidth = wallBoxRef.current?.offsetWidth;
      const REDUCE_FACTOR = 0.93;
      const newWidth = !isWallExtend ? (fullWidth ? fullWidth * REDUCE_FACTOR : '20') : (fullWidth - SIDE_METADATA_SIZE);

      if (fullWidth - newWidth < SIDE_METADATA_SIZE) {
        wallBoxRef.current.style.flexDirection = 'column';
        if (wallScroll.current?.style?.maxWidth !== undefined) wallScroll.current.style.maxWidth = `${fullWidth}px`;
        wallViewRef.current.style.width = `${fullWidth}px`;
      } else {
        wallBoxRef.current.style.flexDirection = 'row';
        if (wallScroll.current?.style?.maxWidth !== undefined) wallScroll.current.style.maxWidth = isWallExtend ? '100%' : `${fullWidth * 0.7}px`;
        wallViewRef.current.style.width = `${newWidth}px`;
      }
    }
  };
  useEffect(() => {
    handleResize(true);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [wallBoxRef]);

  const handleMetadataOpenChange = isOpen => {
    setIsWallExtend(isOpen);
    handleResize(isOpen);
  }

  return (
    <>
      <Modal
        open={modalOpen}
        setOpen={setModalOpen}
        title={t('cws_app.Walls.downloadModalTitle')}
        description={t('cws_app.Walls.downloadModalDescription')}>
        <Button
          variant="contained"
          sx={{ backgroundColor: 'gray' }}
          onClick={() => handleExcelDownload(false)}>
          {t('cws_app.Walls.shelfData')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleExcelDownload(true)}>
          {t('cws_app.Walls.allData')}
        </Button>
      </Modal>
      <Box ref={wallBoxRef} sx={{ mt: 1, display: 'flex', gap: '0.5em', flexDirection: 'row', width: '100%' }}>
        <Collapse
          in={isWallExtend}
          collapsedSize={wallBoxRef.current?.offsetWidth * 0.7 ?? '50em'}
          orientation="horizontal"
          sx={{ flex: 'none' }}
        >
          <Grid container ref={wallViewRef} sx={{ height: '100%' }}>
            <Grid xs={12} ref={wallScroll} id="wallScroll" item container sx={{ maxWidth: isWallExtend ? '100%' : `${wallBoxRef.current?.offsetWidth * 0.7}px`, maxHeight: '32em' }}>
              <Grid
                item
                direction={'row'}
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 2, sm: 8, md: 12 }}
                sx={{
                  height: '100%',
                  width: '100%',
                  borderRadius: '5px',
                  backgroundColor: '#1a242d',
                  WebkitOverflowScrolling: 'touch !important',
                  minHeight: '400px',
                  overflowX: 'auto',
                  overflowY: 'hidden',
                  position: 'relative',
                  display: 'flex',
                }}>
                {!isLoadingWallImage &&
                  Object.keys(wallImageData).length > 0 && //image loaded
                  !isLoadingCoordsInfo ? (
                  <>
                    {' '}
                    {zoom ? (
                      <>
                        {/* bug drawer wall canvas */}
                        <WallDrawer
                          aisle={selectedAisle}
                          landmark={selectedLandmark}
                          png={wallImageData.signed_url}
                          imageMetadata={wallImage}
                          coords={landmarkInfo.coords}
                          wallLandmarks={wallLandmarks}
                          canvasHeight={canvasHeight}
                          canvasWidth={canvasWidth}
                          originalWidth={originalWidth}
                          originalHeight={originalHeight}
                          landmarksVisible={landmarksVisible}
                          selectedStatus={selectedStatus}
                          selectedCategories={selectedCategories}
                          colors={colors}
                          product={selectedProductState}
                          handleCanvasHover={handleCanvasHover}
                          handleCanvasLeave={handleCanvasLeave}
                          handleCanvasClick={handleCanvasClick}
                          aislePromotions={aislePromotions}
                          aisleLandmarks={aisleLandmarks}
                          selectedAisle={selectedAisle}
                          selectedStore={selectedStore}
                          imgsDict={imgsDict}
                          scannedBays={baysDataWithCounts}
                          hoveredElements={hoveredElements.current}
                          handleCoordAlertInfo={handleCoordAlertInfo}
                          getCoordAlertName={getCoordAlertName}
                        />
                      </>
                    ) : (
                      <TransformWrapper>
                        <TransformComponent>
                          <img
                            alt=""
                            height={canvasHeight}
                            width={canvasWidth}
                            src={wallImageData.signed_url}
                          />
                        </TransformComponent>
                      </TransformWrapper>
                    )}{' '}
                  </>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Collapse>
        {/* Wall metadata */}
        <Box sx={{
          width: isWallExtend ? '60px' : `${Math.round(wallBoxRef.current?.offsetWidth * 0.3)}px`,
          webKitTransition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          transition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
        }}>
          <WallMetadata
            parsedLandmarks={parsedLandmarks}
            selectedAisle={selectedAisle}
            isLoadingMaps={isLoadingMaps}
            storeMaps={storeMaps}
            chainName={selectedStore?.chain_name}
            onOpenChange={handleMetadataOpenChange}
            isOpen={!isWallExtend}
            currencySymbol={currencySymbol}
            clientFormats={clientFormats}
          />
        </Box>
      </Box>

      {!isLoadingWallImage &&
        Object.keys(wallImageData).length > 0 &&
        !isLoadingCoordsInfo &&
        Object.keys(landmarkInfo).length > 0 ? (
        <Grid
          item
          xs={12}
          container
          sx={{ mt: 1 }}
          justifyContent="flex-start">
          <Grid
            item
            container
            xs={12}
            sm={'auto'}
            alignContent="center"
            justifyContent="center"
            sx={{ mt: 1, mr: 1, p: 1, borderRadius: 2 }}
            style={{ backgroundColor: '#1a242d' }}>
            <Grid item xs>
              <Tooltip title={t('cws_app.Walls.no_meta_info')}>
                <IconButton
                  color="secondary"
                  size="large"
                  onClick={handleShowInfo}>
                  {landmarksVisible ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Grid>
            {checkLandmarksAvailable() ? (
              <>
                {' '}
                {!loadingCoordsForExcel ? (
                  <Grid item xs>
                    <Tooltip title={t('cws_app.Walls.download_excel')}>
                      <IconButton
                        color="secondary"
                        size="large"
                        onClick={() => setModalOpen(true)}>
                        <InsertDriveFileIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                ) : (
                  <Grid
                    item
                    container
                    xs
                    alignContent="center"
                    justifyContent="center"
                    m={1}>
                    <CircularProgress color="secondary" size={24} sx={{}} />
                  </Grid>
                )}
              </>
            ) : null}
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={6}
            alignContent="center"
            wrap="nowrap"
            sx={{ mt: 1, p: 1, borderRadius: 2 }}
            style={{
              backgroundColor: '#1a242d',
              width: '100%',
              overflowX: 'auto',
            }}>
            {landmarkInfo.coords.length
              ? Object.keys(alertPills).map(pillName => {
                // Bugs filter (the ones at the bottom of the page)
                if (alertPills[pillName] > 0) {
                  return (
                    <Grid item key={pillName}>
                      <Tooltip title={'Total: ' + alertPills[pillName]}>
                        <Chip
                          clickable
                          label={
                            pillName === 'hua'
                              ? t(
                                'Chains.' +
                                selectedStore?.chain_name +
                                '.landmark',
                              )
                              : pillName === 'promotion'
                                ? t('cws_app.digital_store.promotions')
                                : t('cws_app.Walls.' + pillName)
                          }
                          variant="contained"
                          size="large"
                          sx={{
                            m: 1,
                            px: 1,
                            color: colors[pillName].text,
                            fontSize: '14px',
                            fontWeight: '400',
                          }}
                          style={{
                            backgroundColor: selectedStatus.has(pillName)
                              ? colors[pillName].color
                              : colors[pillName].color2,
                          }}
                          onClick={() => {
                            handleSelectionChanged(
                              pillName,
                              selectedStatus,
                              setSelectedStatus,
                            );
                          }}
                        />
                      </Tooltip>
                    </Grid>
                  );
                }
                return null;
              })
              : null}
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={true}
            wrap="nowrap"
            spacing={2}
            sx={{ mt: 1, ml: 1, pt: -1, pb: 1, borderRadius: 2 }}
            style={{
              backgroundColor: '#1a242d',
              width: '100%',
              overflowX: 'auto',
            }}>
            {categoryFilters && categoryFilters.length
              ? categoryFilters.map(cat => {
                return (
                  <Grid item key={cat}>
                    <Chip
                      clickable
                      label={cat}
                      variant="contained"
                      size="large"
                      sx={{
                        color: 'black',
                        fontSize: '14px',
                        fontWeight: '400',
                      }}
                      style={{
                        backgroundColor: !selectedCategories.has(cat)
                          ? '#CACADD'
                          : '#697075',
                      }}
                      onClick={() => {
                        handleSelectionChanged(
                          cat,
                          selectedCategories,
                          setSelectedCategories,
                        );
                      }}
                    />
                  </Grid>
                );
              })
              : null}
          </Grid>
        </Grid>
      ) : null}
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={isLoadingWallImage || isLoadingCoordsInfo}>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          direction="column">
          <Typography>
            {t('cws_app.Walls.loading_wall', 'Loading Wall Image')}
          </Typography>
          <CircularProgress color="secondary" />
        </Grid>
      </Backdrop>
    </>
  );
}
