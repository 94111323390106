import { format as dateFnsFormat } from 'date-fns';
import { de, es, ja } from 'date-fns/locale';
const locales = { 'en': undefined, 'de': de, 'ja': ja, 'es': es };

export function convertMeterToPixel(xMeter, yMeter, scaleValue = 1, WALLQUALITYFACTOR = 2) {
  const sc_rgb = 380.2270100911458 * WALLQUALITYFACTOR;
  const xPixel = xMeter ? Math.round(scaleValue * sc_rgb * xMeter) : null;
  const yPixel = yMeter ? Math.round(scaleValue * (-sc_rgb * yMeter + 2100 * WALLQUALITYFACTOR / 2)) : null;
  return [xPixel, yPixel];
}

// \s is the regex for "whitespace", and g is the "global" flag, meaning match ALL \s (whitespaces) with "replaceWith".
export function replaceWhiteSpace(input, replaceWith = '') {
  return input.replace(/\s+/g, replaceWith)
}

export function getAisleVector({ pixelInit, pixelEnd }) {
  const x = pixelEnd[0] - pixelInit[0];
  const y = pixelEnd[1] - pixelInit[1];
  return [x, y];
}

export function getAisleLength({ pixelInit, pixelEnd }) {
  return Math.sqrt(Math.pow(pixelEnd[0] - pixelInit[0], 2) + Math.pow(pixelEnd[1] - pixelInit[1], 2));
}

export function getIsHorizontal(lowerRightPx, upperLeftPx) {
  return Math.abs(lowerRightPx[0] - upperLeftPx[0]) > Math.abs(lowerRightPx[1] - upperLeftPx[1]);
}

export function getAislePadding() {
  return 10;
}

export function getAisleMeasures({ pixelInit, pixelEnd, includePadding = false }) {
  const isHorizontal = getIsHorizontal(pixelEnd, pixelInit);
  const aisleVector = getAisleVector({ pixelInit, pixelEnd });
  const width = Math.abs(aisleVector[0]) + (includePadding ? (isHorizontal ? 0 : getAislePadding()) : 0);
  const height = Math.abs(aisleVector[1]) + (includePadding ? (isHorizontal ? getAislePadding() : 0) : 0);
  return { width, height };
}

export function getTopAisleCoords({ pixelInit, pixelEnd }) {
  return [Math.min(pixelInit[0], pixelEnd[0]), Math.min(pixelInit[1], pixelEnd[1])];
}

export function getRobotIndex({ session, floorIdentifier = 'Piso', defaultIndex = 1 }) {
  let robotIndex = session && session.includes(floorIdentifier) ? session.split(floorIdentifier)[1] : defaultIndex;
  robotIndex = parseInt(robotIndex ?? defaultIndex);
  return robotIndex;

}

export function checkFormat(formatLocale = null) {
  const formatPattern = formatLocale ? formatLocale.replaceAll('Y', 'y').replaceAll('D', 'd') : null;
  return formatPattern;
}

export function format(date, locale = null, formatLocale = null) {
  const formatPattern = checkFormat(formatLocale);
  return formatLocale ? dateFnsFormat(date, formatPattern) : dateFnsFormat(date, 'P', { 'locale': locales[locale] });
}

export function pointToBox({ pixel_x, pixel_z, radius = 19, scale_x = 1, scale_y = 1 }) {
  const x1 = (pixel_x * scale_x) - radius;
  const y1 = (pixel_z * scale_y) - radius;
  const x2 = x1 + (radius * 2);
  const y2 = y1 + (radius * 2);

  return { x1, y1, x2, y2 };
}

/**
 * Calculates user's time offset and converts a UTC date to local time
 * @param {Date|string} utcDate - UTC date (can be Date object or ISO string)
 * @returns {{
*   offsetMinutes: number,
*   localDate: Date,
*   formattedLocal: string
* }} Object with offset in minutes, local date and formatted string
*/
export const getLocalTimeFromUTC = (utcDate) => {
  // Get user's timezone offset in minutes
  const offsetMinutes = new Date().getTimezoneOffset();

  // Convert parameter to Date object if it's a string
  const utcDateTime = typeof utcDate === 'string' ? new Date(utcDate) : utcDate;

  // Convert to local time
  const localDate = new Date(utcDateTime.getTime() - (offsetMinutes * 60000));

  // Format date for display
  const formattedLocal = localDate.toLocaleString(undefined, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  });

  return {
    offsetMinutes,
    localDate,
    formattedLocal
  };
};